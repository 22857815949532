import Contact from "../../components/contact/Contact";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import Promo from "../../components/promo/Promo";
import Screenshot from "../../components/screenshot/Screenshot";
import Team from "../../components/team/Team";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroFour from "./HeroFour";

export default function HomeFour() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroFour />
      <Promo />
      <FeatureImgThree />
      <FeatureImgTwo />
      <BrandCarousel />
      <Screenshot />
      <Team bgColor="lightBlue-bg" />
      <Contact bgColor />
      <Subsribe />
      <Footer />
    </Layout>
  );
}
