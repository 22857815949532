
const colors = {

    mainBlue: '#01193D',
    lightBlue: '#06E5FC',
    grey: '#353535',
    whitesmoke: '#F4F7FA',
    white: '#FFFFFF'
};

export default colors;