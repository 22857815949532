import HomeButton from "../../components/button/HomeButton";
import colors from "../../shared/colors";

export default function HeroFour() {

  const scrollToBottom = () => {
    let element = document.getElementById("contact");
    element.scrollIntoView({behavior: "smooth"});
  };

  return (
    <>
      <section id="home" className="hero-section hero-section-2 ptb-100">
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>
          <div className="point animated-point-7"></div>
          <div className="point animated-point-8"></div>
          <div className="point animated-point-9"></div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left ptb-100 text-white">
                <h1 className="text-white font-weight-bolder" style={{width:'30.8rem', margin: '0'}}>
                  Bringing Out 
                </h1>
                <h1 className="text-white font-weight-bolder" style={{width:'30.8rem'}}>
                  the Best From Tech
                </h1>
                <p className="lead" style={{fontSize: '1.2rem', lineHeight: '1.5rem', fontFamily: '"Montserrat", sans-serif', width: '30.8rem', fontWeight: '400'}}>
                  We generate the highest long-term value through 
                  the technology and knowledge at your disposal
                </p>

                {/* <a href="#contact" className="btn app-store-btn">
                  Contact us
                </a> */}
                <HomeButton
                  label={'Contact us'}
                  onClick={() => scrollToBottom()}
                  colorMain={colors.mainBlue}
                  colorHover={colors.lightBlue}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
                <img
                  className="img-fluid d-block animation-one"
                  src="assets/img/monitor.svg"
                  alt="animation"
                  style={{height: '30rem'}}
                />
                <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src="assets/img/heroSmall1.svg"
                  alt="animation "
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-three"
                  src="assets/img/heroSmall2.svg"
                  alt="animation "
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src="assets/img/heroSmall3.svg"
                  alt="animation "
                  width="230"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
