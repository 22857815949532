import React from "react";
import OwlCarousel from "react-owl-carousel";
import { BodyHeader30, BodyText12 } from "../../shared/fonts";

export default function BrandCarousel({ hasBg }) {
  const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: true,
    slidetransition: "linear",
    autoplayTimeout: 4500,
    autoplayHoverPause: true,
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <>
      <section
        className={`client-section ptb-100 ${hasBg ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                {hasBg ? (
                  <BodyHeader30 style={{marginBottom: '1.2rem'}}>
                    Technologies that we use
                  </BodyHeader30>
                ) : (
                  ""
                )}
                <BodyHeader30 style={{marginBottom: '1.2rem'}}>Technologies that we use</BodyHeader30>
                <BodyText12>
                  We are always up to date with cutting edge technology stack.
                </BodyText12>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-carousel owl-theme clients-carousel dot-indicator"
                {...options}
              >
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/reactJSIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/springIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/nodeIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/dockerIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/kuberIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/hyperledgerIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/kafkaIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/javaEEIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/angularIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/ethIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/reactIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technologies/redisIcon.svg"
                    alt="client logo"
                    className="client-img"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
