import styled from 'styled-components';
import { Row } from '../../shared/layout';
import colors from '../../shared/colors';
import { ButtonText } from '../../shared/fonts';

export const Wrapper = styled(Row)`
  border-radius: 5rem;
  height: 2.8rem;
  box-sizing: border-box;
  width: 11rem;
  cursor: pointer;
  background: ${props => props.colorMain};
  padding: 0.5rem 1.2rem;
  justify-content: center;
  box-shadow: 0px 0px 8px ${props => props.colorHover};
  border: 2px solid ${props => props.colorHover};
  color: ${props => props.colorHover};
  :hover {
    background: ${props => props.colorHover};
    color: ${props => props.colorMain};
  }

  /* @media (max-width: 875px) {
    width: 15rem;
    padding: 0.8rem 2.4rem;
  } */
`;

export const LabelWrapper = styled(ButtonText)`
  @media (max-width: 875px) {
    font-size: 1rem;
  }
  font-size: 1rem;
  line-height: 1.5rem;
  
`;
