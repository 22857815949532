import axiosInstance from '../axios';

export const getCaptchaToken = (token) => {
  return axiosInstance.get(`token-checker?token=${token}`);
};

export const sendEmailForm = (form) => {
  return axiosInstance.post(`sendEmail`, form);
};

export const sendCvForm = (form) => {
  return axiosInstance.post(`sendCv`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

