import React from "react";
import { BodyText12, BodyHeader30, PromoHeader12, BodyText10 } from "../../shared/fonts";
import { PromoIconFour, PromoIconOne, PromoIconThree, PromoIconTwo, PromoIconWhy1, PromoIconWhy2, PromoIconWhy3, PromoIconWhy4 } from "../../shared/icons";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <BodyHeader30 style={{marginBottom: '1.2rem'}}>Why choose LittleBit?</BodyHeader30>
                <BodyText12>
                As a software engineering company, we take pride in our service of technical excellence, and a thorough understanding of how technology can generate business value for our clients. 
                Since 2017, we have proven ourselves as reliable outsourcing partners to clients from the United Kingdom, Malta, Norway, Sweden, and the USA. 
                We complete the circle from the idea to the full implementation.
                </BodyText12>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-bmd-4 col-blg-4" style={{padding: '0 1.2rem 0 0'}}>
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                {/* <div className="circle-icon mb-5">
                  <span className="text-white">
                    <PromoIconOne style={{height: '2.5rem'}}/>
                  </span>
                </div> */}
                <PromoIconWhy1/>
                <PromoHeader12 style={{marginTop: '0.6rem', marginBottom: '0.6rem'}}>UI/UX Design</PromoHeader12>
                <BodyText10>Design ideas and complete UX/UI product design, including MVP projects.</BodyText10>
              </div>
            </div>
            <div className="col-bmd-4 col-blg-4" style={{padding: '0 1.2rem 0 0'}}>
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <PromoIconWhy2/>
                <PromoHeader12 style={{marginTop: '0.6rem', marginBottom: '0.6rem'}}>Development</PromoHeader12>
                <BodyText10>
                  Designing, modeling, implementing, and testing software products.
                </BodyText10>
              </div>
            </div>
            <div className="col-bmd-4 col-blg-4" style={{padding: '0 1.2rem 0 0'}}>
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <PromoIconWhy3/>
                <PromoHeader12 style={{marginTop: '0.6rem', marginBottom: '0.6rem'}}>DevOps</PromoHeader12>
                <BodyText10>
                  Deployment to the desired cloud platform, or on-premise deployment.
                </BodyText10>
              </div>
            </div>
            <div className="col-bmd-4 col-blg-4" style={{padding: '0 1.2rem 0 0'}}>
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <PromoIconWhy4/>
                <PromoHeader12 style={{marginTop: '0.6rem', marginBottom: '0.6rem'}}>Monitoring and maintenance</PromoHeader12>
                <BodyText10>Technical maintenance, monitoring services, and bug solving.</BodyText10>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
